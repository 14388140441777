import client from "../../client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ClientExpectedVisitsQueryResponse } from "../types/ClientExpectedVisits";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ClientExpectedVisitsClient = typeof client<ClientExpectedVisitsQueryResponse, never, never>;
type ClientExpectedVisits = {
    data: ClientExpectedVisitsQueryResponse;
    error: never;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ClientExpectedVisitsQueryResponse;
    client: {
        parameters: Partial<Parameters<ClientExpectedVisitsClient>[0]>;
        return: Awaited<ReturnType<ClientExpectedVisitsClient>>;
    };
};
export const clientExpectedVisitsQueryKey = () => [{ url: "/vat-client/expected-visits" }] as const;
export type ClientExpectedVisitsQueryKey = ReturnType<typeof clientExpectedVisitsQueryKey>;
export function clientExpectedVisitsQueryOptions(options: ClientExpectedVisits["client"]["parameters"] = {}) {
    const queryKey = clientExpectedVisitsQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ClientExpectedVisits["data"], ClientExpectedVisits["error"]>({
                method: "get",
                url: `/vat-client/expected-visits`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Return a list of expected visits of today that are not yet checked in
 * @link /vat-client/expected-visits
 */
export function useClientExpectedVisits<TData = ClientExpectedVisits["response"], TQueryData = ClientExpectedVisits["response"], TQueryKey extends QueryKey = ClientExpectedVisitsQueryKey>(options: {
    query?: Partial<QueryObserverOptions<ClientExpectedVisits["response"], ClientExpectedVisits["error"], TData, TQueryData, TQueryKey>>;
    client?: ClientExpectedVisits["client"]["parameters"];
} = {}): UseQueryResult<TData, ClientExpectedVisits["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? clientExpectedVisitsQueryKey();
    const query = useQuery({
        ...clientExpectedVisitsQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ClientExpectedVisits["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const clientExpectedVisitsSuspenseQueryKey = () => [{ url: "/vat-client/expected-visits" }] as const;
export type ClientExpectedVisitsSuspenseQueryKey = ReturnType<typeof clientExpectedVisitsSuspenseQueryKey>;
export function clientExpectedVisitsSuspenseQueryOptions(options: ClientExpectedVisits["client"]["parameters"] = {}) {
    const queryKey = clientExpectedVisitsSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ClientExpectedVisits["data"], ClientExpectedVisits["error"]>({
                method: "get",
                url: `/vat-client/expected-visits`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Return a list of expected visits of today that are not yet checked in
 * @link /vat-client/expected-visits
 */
export function useClientExpectedVisitsSuspense<TData = ClientExpectedVisits["response"], TQueryKey extends QueryKey = ClientExpectedVisitsSuspenseQueryKey>(options: {
    query?: Partial<UseSuspenseQueryOptions<ClientExpectedVisits["response"], ClientExpectedVisits["error"], TData, TQueryKey>>;
    client?: ClientExpectedVisits["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ClientExpectedVisits["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? clientExpectedVisitsSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...clientExpectedVisitsSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ClientExpectedVisits["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}