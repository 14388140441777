import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";
import {
  ClientCheckinNewRequest,
  Organizer,
  useClientCheckinNew,
  useClientExpectedVisits,
  Visit,
} from "../../api/__generated__";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { BaseCheckInForm } from "../../types/baseCheckInForm";
import { Input } from "../../components/input";
import { validationRules } from "../../util/form-validation";
import { FormSearchableField } from "../../components/form-searchable-field";
import { Option } from "../../types/reactSelectOption";

export const CheckIn = () => {
  const { state, dispatch } = useAppData();
  const { data: expectedCheckins, error: expectedCheckinsError } =
    useClientExpectedVisits();
  const navigate = useNavigate();
  const [clientCheckinNewRequestData, setClientCheckinNewRequestData] =
    useState<ClientCheckinNewRequest | null>(null);
  const [options, setOptions] = useState<Option[]>();

  const { handleSubmit, control } = useForm<BaseCheckInForm>({
    defaultValues: {
      name: "",
      appointmentWithId: "",
      organization: "",
    },
  });

  useEffect(() => {
    if (expectedCheckins) {
      const options = expectedCheckins.map((visit: Visit) => ({
        value: visit.visitor.id || "",
        label: visit.visitor.name,
      }));
      setOptions(options);
    }

    if (expectedCheckinsError) {
      console.error("Error fetching expected checkins:", expectedCheckinsError);
      dispatch({
        type: actions.SET_ERROR,
        payload: expectedCheckinsError,
      });
    }
  }, [dispatch, expectedCheckins, expectedCheckinsError]);

  const checkinNew = useClientCheckinNew({
    mutation: {
      onSuccess: () => {
        dispatch({
          type: actions.SET_CLIENT_CHECKIN_NEW,
          payload: clientCheckinNewRequestData,
        });
        navigate({ pathname: pages.CONFIRMATION, search: "?checkedIn=true" });
      },
      onError: (error) => {
        console.error("Error during check in:", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message:
              "Something went wrong during check in, please try again later.",
          },
        });
      },
    },
  });

  const submitForm: SubmitHandler<BaseCheckInForm> = (formData) => {
    if (!state.clientSession || !formData) return;
    const data = {
      ...formData,
      organization: formData.organization || "",
      location: state.clientSession?.location || "",
    };
    setClientCheckinNewRequestData(data);
    checkinNew.mutate(data);
  };

  return (
    <PageTemplateClient navigateToHome={true}>
      <article>
        <h1 data-testid="page-title" className="mb-6 text-4xl">
          Please fill in your information
        </h1>
        <p>
          It takes less than 5 minutes to fill out all the information needed to
          register you.
        </p>
      </article>
      <form
        className="w-[55vw] overflow-hidden "
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="flex flex-col gap-12 mb-14">
          <FormSearchableField<BaseCheckInForm>
            label={"Full name *"}
            name={"name"}
            placeholder={"Full name"}
            rules={validationRules.nameManualInput}
            control={control}
            options={options}
          />
          <Input<BaseCheckInForm>
            label="Organization (optional)"
            name="organization"
            placeholder="Organization"
            inputClassName="pl-2"
            control={control}
          />
          <FormSearchableField<BaseCheckInForm>
            label={"Appointment with *"}
            name={"appointmentWithId"}
            placeholder={"Select an organizer"}
            rules={validationRules.appointmentWith}
            control={control}
            options={state.organizers?.map((org: Organizer) => ({
              value: org.id,
              label: org.name,
            }))}
            menuPortalTarget={document.body}
            optionSelectionRequired={true}
          />
        </div>
        <Button
          variant={"primary"}
          data-testid="btn-check-in-out"
          isLoading={checkinNew.isPending}
        >
          Check in
        </Button>
      </form>
    </PageTemplateClient>
  );
};
