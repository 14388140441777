import { useEffect, useState } from "react";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useClientCheckout,
  useClientExpectedCheckouts,
} from "../../api/__generated__/hooks";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { Visit, Visitor } from "../../api/__generated__/types";
import { useNavigate } from "react-router";
import { pages } from "../../util/pages";
import { Option } from "../../types/reactSelectOption";
import { FormSearchableField } from "../../components/form-searchable-field";
import { validationRules } from "../../util/form-validation";

type CheckoutForm = {
  name: string;
};

export const CheckOut = () => {
  const { dispatch } = useAppData();
  const { data: expectedCheckoutsData, error: expectedCheckoutsError } =
    useClientExpectedCheckouts();
  const [options, setOptions] = useState<Option[]>();
  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const navigate = useNavigate();
  const { nameSelection: nameSelectionRules } = validationRules;

  const { handleSubmit, control } = useForm<CheckoutForm>({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (expectedCheckoutsData) {
      const options = expectedCheckoutsData.map((visit: Visit) => ({
        value: visit.visitor.id || "",
        label: visit.visitor.name,
      }));
      setOptions(options);
    }

    if (expectedCheckoutsError) {
      console.error(
        "Error fetching expected checkouts:",
        expectedCheckoutsError
      );
      dispatch({
        type: actions.SET_ERROR,
        payload: expectedCheckoutsError,
      });
    }
  }, [dispatch, expectedCheckoutsData, expectedCheckoutsError]);

  const submitForm: SubmitHandler<CheckoutForm> = (formData) => {
    if (!formData.name) return;

    const currentVisit = expectedCheckoutsData?.find(
      (visit) => visit.visitor.id == formData.name
    );
    if (!currentVisit || !currentVisit.id) return;

    setVisitor(currentVisit.visitor);
    checkOutClient.mutate({ visitId: currentVisit.id });
  };

  const checkOutClient = useClientCheckout({
    mutation: {
      onSuccess: () => {
        dispatch({
          type: actions.SET_CHECKED_OUT_VISITOR,
          payload: visitor,
        });
        navigate({ pathname: pages.CONFIRMATION, search: "?checkedIn=false" });
      },
      onError: (error) => {
        console.error("Error during check out:", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message:
              "Something went wrong during check out, please try again later.",
          },
        });
      },
    },
  });

  return (
    <PageTemplateClient navigateToHome={true}>
      <article>
        <h1 data-testid="page-title" className="mb-2 text-4xl">
          Please fill in your information to check out
        </h1>
        <p>Do not forget to return your badge afterwards</p>
      </article>
      <form
        className="w-[55vw] overflow-hidden"
        onSubmit={handleSubmit(submitForm)}
      >
        <FormSearchableField<CheckoutForm>
          label={"Full name *"}
          name={"name"}
          placeholder={"Full name"}
          rules={nameSelectionRules}
          control={control}
          options={options}
          optionSelectionRequired={true}
        />
        <Button
          variant={"primary"}
          className={"mt-14"}
          data-testid="btn-check-in-out"
          isLoading={checkOutClient.isPending}
        >
          Check out
        </Button>
      </form>
    </PageTemplateClient>
  );
};
